<template>
  <main>
    <template v-if="this.user.level.nama_level == 'Admin Gudang'">
      <b-row>
        <b-col>

          <dashboard-gudang></dashboard-gudang>
        </b-col>
      </b-row>
    </template>
    <template v-else>
      <b-row>
        <b-col cols="12">
          <b-alert show variant="warning" class="p-1">
            <span class="text-dark">Kelengkapan data diri anda sebesar <strong>75%</strong>, silahkan lengkapi data diri
              untuk mendapat keuntungan lainnya. <a @click.prevent="$router.push(`/akun-pengguna`)"
                class="alert-link">Lengkapi Sekarang</a></span>
          </b-alert>
        </b-col>
        <b-col sm="12" md="4" lg="3">
          <b-card>
            <div class="d-flex align-items-center justify-content-start">
              <section class="icon">
                <b-avatar :variant="`light-success`" size="50">
                  <feather-icon size="24" icon="DollarSignIcon" />
                </b-avatar>
              </section>
              <section class="info ml-2">
                <strong class="d-block" style="margin-bottom: 5px"><small>Komisi Anda</small></strong>
                <h2><strong>500k</strong></h2>
              </section>
            </div>
          </b-card>
        </b-col>
        <b-col sm="12" md="4" lg="3">
          <b-card>
            <div class="d-flex align-items-center justify-content-start">
              <section class="icon">
                <b-avatar :variant="`light-danger`" size="50">
                  <feather-icon size="24" icon="StopCircleIcon" />
                </b-avatar>
              </section>
              <section class="info ml-2">
                <strong class="d-block" style="margin-bottom: 5px"><small>Poin Anda</small></strong>
                <h2><strong>1405</strong></h2>
              </section>
            </div>
          </b-card>
        </b-col>
        <b-col sm="12" md="4" lg="3">
          <b-card>
            <div class="d-flex align-items-center justify-content-start">
              <section class="icon">
                <b-avatar :variant="`light-warning`" size="50">
                  <feather-icon size="24" icon="UserCheckIcon" />
                </b-avatar>
              </section>
              <section class="info ml-2">
                <strong class="d-block" style="margin-bottom: 5px"><small>Konsumen Anda</small></strong>
                <h2><strong>92</strong></h2>
              </section>
            </div>
          </b-card>
        </b-col>
      </b-row>
      <b-card>
        <b-row>
          <b-col md="6">
            <h4 class="text-bold">Bagaimana menggunakan program referral</h4>
            <section class="icon"><feather-icon class="text-success mr-1" size="20" icon="CheckCircleIcon" />1. Salin
              tautan rujukan</section>
            <section class="icon"><feather-icon class="text-success mr-1" size="20" icon="CheckCircleIcon" />2. Bagikan
              dengan temen anda dan orang lain</section>
            <section class="icon"><feather-icon class="text-success mr-1" size="20" icon="CheckCircleIcon" />3. Hasil uang
              dari langganan mereka</section>
          </b-col>
          <b-col md="6">
            <h4 class="text-bold">Tautan Referensi Anda</h4>
            Undang teman Anda dan orang lain dan dapatkan komisi dari langganan mereka
            <b-row>
              <b-col md="8">
                <b-form-input readonly class="mt-1" style="font-size: 13px"
                  :value="'https://armezza.com/affiliate?member=' + user.karyawan.id"></b-form-input>
              </b-col>
              <b-col md="4">
                <b-button class="mt-1" variant="primary"
                  @click="copyUrl('https://armezza.com/affiliate?member=' + user.karyawan.id)">Copy Link</b-button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-card>
    </template>
  </main>
</template>
<script>
import { BAlert, BAvatar, BRow, BCol, BCard, BFormInput, BButton } from "bootstrap-vue";
import DashboardGudang from './owner/DashboardGudang'
export default {
  data() {
    return {
      level: JSON.parse(localStorage.getItem("userData")).level,
    };
  },
  components: {
    DashboardGudang,
    BAlert,
    BAvatar,
    BRow,
    BCol,
    BCard,
    BFormInput,
    BButton,
  },
  computed: {
    myName() {
      if (this.user) {
        return this.user.karyawan ? this.user.karyawan.nama_lengkap : this.user.email;
      }

      return null;
    },
  },
  methods: {
    copyUrl(link) {
      navigator.clipboard.writeText(`${link}`);
      this.displaySuccess({
        message: "Berhasil disalin",
      });
    },
  },
};
</script>
